import logo from '@/assets/logo.svg';
import InputSelect from '@/components/element/inputs/InputSelect';
import InputText from '@/components/element/inputs/InputText';
import AuthLayout from '@/components/layout/auth/AuthLayout';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import { EnterpriseTokenStatus } from '@/features/dashboard/types/enterprise.types';
import { useEffect } from 'react';
import { useOnboarding } from '../hooks/useOnboardingV2';
import { tokenStatus } from '../types/onboarding-options';
import { Input } from '@/components/ui/input';
import Spinner from '@/components/element/loading/Spinner';

export default function OnboardingUser() {
	const {
		community,
		setCommunity,
		isLoading,
		errors,
		handleCommunitySetup,
		setErrors,
		setSimilarCommunity,
		openAccordian,
		setOpenAccordion,
		handleAccordionChange,
		checkIfCommunityNameExists,
		checkingSimilarCommunity,
		similarCommunity,
		checkIfTwitterHandleExists,
		twitterHandle,
		setTwitterHandle,
	} = useOnboarding();

	useEffect(() => {
		if (localStorage.getItem('referral-code')) {
			setOpenAccordion('referralCode');
		}
	}, []);

	useEffect(() => {
		setErrors({
			name: false,
			twitter: false,
		});
	}, [community.name, community.twitter, setErrors]);

	const renderNameAppend = () => {
		const wrapperClass = 'mx-2 mt-1 w-5 flex justify-center';

		// Show loading state during API check
		if (checkingSimilarCommunity) {
			return (
				<div className={wrapperClass}>
					<Spinner />
				</div>
			);
		}

		// After API check, show appropriate icon
		if (!similarCommunity) {
			return (
				<div className={wrapperClass}>
					<i className="bi bi-check-circle-fill text-green-500"></i>
				</div>
			);
		}

		return (
			<div className={wrapperClass}>
				<i className="bi bi-x-circle-fill text-red-500"></i>
			</div>
		);
	};

	const renderTwitterAppend = () => {
		const wrapperClass = 'mx-2 mt-1 w-5 flex justify-center';

		// Show loading state during API check
		if (twitterHandle.isLoading) {
			return (
				<div className={wrapperClass}>
					<Spinner />
				</div>
			);
		}

		// Show dash icon in initial state
		if (!twitterHandle.verified && !twitterHandle.associatedTo) {
			return (
				<div className={wrapperClass}>
					<i className="bi bi-dash-circle-fill text-slate-500"></i>
				</div>
			);
		}

		// Show red X only if the handle is associated with another project
		if (twitterHandle.associatedTo) {
			return (
				<div className={wrapperClass}>
					<i className="bi bi-x-circle-fill text-red-500"></i>
				</div>
			);
		}

		// Show green check if verified or if there's no association
		return (
			<div className={wrapperClass}>
				<i className="bi bi-check-circle-fill text-green-500"></i>
			</div>
		);
	};

	return (
		<AuthLayout>
			<div className="mx-auto flex w--full flex-col justify-center items-center sm:w-[420px] px-8 lg:px-0 text-center">
				<div className="w-full">
					<div className="mb-5 pb-5 text-center flex flex-col items-center">
						<img src={logo} className="h-10 mb-5" />
						<h3 className="text-lg font-medium">
							Setup your community profile
						</h3>
						<p className="text-muted-foreground mt-1 text-sm px-10">
							Please provide the following details to setup your
							community profile
						</p>
					</div>
					<InputText
						label="Project Name"
						required={true}
						placeholder="Uniswap"
						value={community.name}
						setValue={(e) => {
							if (similarCommunity) {
								setSimilarCommunity(null);
							}
							setCommunity((p) => ({
								...p,
								name: e,
							}));
							setSimilarCommunity(null);
						}}
						error={errors.name}
						append={renderNameAppend()}
						errorText="Please enter your project name"
						className="text-left mb-6"
						labelClassName="font-normal"
						onBlur={() => {
							if (community.name) {
								checkIfCommunityNameExists(community.name?.trim());
							}
						}}
					/>
					{!checkingSimilarCommunity && similarCommunity && (
						<div className="text-xs text-destructive text-left mb-6 -mt-5">
							<p>
								An approved project, {similarCommunity?.name},
								already exists with similar project name. You can
								check the project
								<a
									href={similarCommunity?.link}
									className="underline ms-1 underline-offset-1"
									target="_blank"
								>
									here
								</a>
							</p>
						</div>
					)}
					<InputText
						label="Project Twitter"
						required={true}
						placeholder="intractCampaign"
						prepend={
							<div className="ps-3 pe-2 flex items-center text-xs">
								<i className="bi bi-twitter text-twitter pe-2"></i>
								<span className="text-muted-foreground font-medium">
									https://twitter.com/
								</span>
							</div>
						}
						value={community.twitter}
						setValue={(e) => {
							setCommunity((p) => ({
								...p,
								twitter: e,
							}));
							setTwitterHandle((prev) => ({
								...prev,
								verified: false,
								associatedTo: '',
							}));
						}}
						errorText="Please enter your twitter username"
						error={errors.twitter}
						className="text-left mb-3"
						labelClassName="font-normal"
						append={renderTwitterAppend()}
						onBlur={() => {
							if (community.twitter) {
								checkIfTwitterHandleExists(community.twitter);
							}
						}}
					/>
					{!twitterHandle.isLoading && !!twitterHandle.associatedTo && (
						<div className="text-xs text-destructive text-left mb-1 -mt-1">
							<p>
								This Twitter account is already linked to an approved
								enterprise in Intract and associated with an active
								campaign.
							</p>
						</div>
					)}
					<Accordion
						type="single"
						collapsible
						className="w-full mb-7"
						value={openAccordian}
					>
						<AccordionItem value="referralCode">
							<AccordionTrigger
								className="text-left font-normal text-sm focus:ring-0 focus:outline-none focus:border-none focus-visible:ring-0 focus-visible:outline-none"
								onClick={() => handleAccordionChange('referralCode')}
							>
								Have a referral code?
							</AccordionTrigger>
							<AccordionContent className="text-gray-500 transition-all">
								<Input
									id="referralCode"
									placeholder="Enter referral code"
									type="text"
									autoCapitalize="none"
									className="focus:ring-0 focus:outline-none focus-visible:ring-0 focus-visible:outline-none text-primary"
									autoCorrect="off"
									value={community.referralCode}
									onChange={(e) =>
										setCommunity((p) => ({
											...p,
											referralCode: e.target.value,
										}))
									}
								/>
							</AccordionContent>
						</AccordionItem>
					</Accordion>
					<InputSelect
						label="What is your token status?"
						placeholder="Select token status"
						options={tokenStatus}
						value={community.tokenStatus}
						setValue={(e) =>
							setCommunity((p) => ({
								...p,
								tokenStatus: e,
							}))
						}
						errorText="Please select a category"
						className="text-left mb-3"
						placeholderClassName="!text-muted-foreground"
						labelClassName="font-normal"
					/>
					{community.tokenStatus === EnterpriseTokenStatus.Listed ? (
						<div className="flex gap-4 w-full">
							<InputText
								label="Chain Id"
								placeholder="1234"
								value={community?.tokenDetails?.tokenChainId}
								setValue={(e) => {
									const numericValue = Number(e);
									if (Number.isNaN(numericValue)) {
										// If the value is not a number, return early
										return;
									}
									setCommunity((p) => ({
										...p,
										tokenDetails: {
											...p.tokenDetails,
											tokenChainId: numericValue,
										},
									}));
								}}
								className="text-left w-[20%]"
								labelClassName="font-normal"
							/>
							<InputText
								label="Token Address"
								placeholder="0xb851220202C019d1645CB9f75dcc5ef7a66aaca9"
								value={community?.tokenDetails?.tokenAddress}
								setValue={(e) =>
									setCommunity((p) => ({
										...p,
										tokenDetails: {
											...p.tokenDetails,
											tokenAddress: e,
										},
									}))
								}
								className="text-left w-[80%]"
								labelClassName="font-normal"
							/>
						</div>
					) : null}

					<div className="mt-5 flex gap-3">
						<Button
							onClick={handleCommunitySetup}
							disabled={
								isLoading ||
								checkingSimilarCommunity ||
								twitterHandle?.isLoading
							}
							className="flex-grow"
						>
							{isLoading ? (
								<span>Loading...</span>
							) : (
								<span>Continue</span>
							)}
						</Button>
					</div>
				</div>
			</div>
		</AuthLayout>
	);
}
