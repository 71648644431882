import { ICampaignReward, ISpeedRunConfig } from '../../types';
import { BigNumber, ethers } from 'ethers';
import { useReadContracts } from '@/hooks/useReadContracts';
import erc20Abi from '@/config/abi/ERC20ABI.json';
import { ERC20RewardABI } from '@/config/abi/ERC20RewardABI';
import { useAccount } from 'wagmi';
import { toast } from 'sonner';
import { useCreateTxn } from '@/hooks/useCreateTxn';
import { ContractType, useGetContract } from '@/hooks/useGetContract';
import {
	getTokenRewardTransferSignature,
	updateCampaignAdminAddress,
	updateSpeedrunTransferTxn,
	updateTokenRewardApprovalTxn,
	updateTokenRewardTransferTxn,
} from '../../services/campaigns.service';
import { useEffect, useMemo, useState } from 'react';
import { handleErrorMessage } from '@/utils/notifications';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { SPEEDRUN_USER_ADDRESS } from '@/config';
import { getScanLink } from '@/utils/blockChain';
import { createErrorMessage, useDepositTokenStatus } from './useDepositTokenStatus';
import { queryClient } from '@/lib/react-query';

export const useDepositToken = ({
	campaignId,
	tokenReward,
	speedRunConfig,
}: {
	campaignId: string;
	tokenReward: ICampaignReward;
	speedRunConfig: ISpeedRunConfig;
}) => {
	const { contract } = useGetContract(
		ContractType.IntractCampaignRewardToken,
		tokenReward?.tokenReward?.chain,
		tokenReward?.tokenReward?.namespaceTag,
	);
	const [status, setStatus] = useState('Connect Wallet');
	const [isLoading, setIsLoading] = useState(false);
	const chainId = Number(tokenReward?.tokenReward?.chainId);
	const { address, chainId: selectedChainId } = useAccount();
	const [userBalance, setUserBalance] = useState('');
	const { readContract } = useReadContracts();
	const { startTxn } = useCreateTxn();
	const [isSpeedrunDone, setIsSpeedrunDone] = useState(false);

	const { steps, resetSteps, updateSteps } = useDepositTokenStatus({ isLoading });

	const requiredAmount = useMemo(() => {
		if (!tokenReward) return '';
		try {
			let tokenRewardPool = (
				tokenReward?.tiersRewardPool && tokenReward?.tiers?.length > 0
					? Number(tokenReward?.tiersRewardPool)
					: Number(tokenReward?.tokenReward?.tokenAmountPerUser) *
						tokenReward?.numRewards
			)?.toFixed(tokenReward?.tokenReward?.tokenDecimals);

			if (speedRunConfig?.isEnrolled) {
				const rewardPool = Number(speedRunConfig?.tokenAmount);
				tokenRewardPool = (+tokenRewardPool + rewardPool)?.toString();
			}

			return ethers.utils.parseUnits(
				tokenRewardPool?.toString(),
				tokenReward?.tokenReward?.tokenDecimals,
			);
		} catch (err) {
			toast.error('Failed to calculate required amount');
			console.log(err);
			return 0;
		}
	}, [tokenReward, speedRunConfig]);

	const startProcess = async () => {
		try {
			setIsLoading(true);
			resetSteps();
			console.log('1/checkRequiredBalance');
			await checkRequiredBalance();
			console.log('2/startApprovalTransaction');
			await startApprovalTransaction();
			if (speedRunConfig?.isEnrolled) {
				console.log(`3/startSpeedRunTransaction`);
				await startSpeedRunTransaction();
			}
			console.log('4/startTransferTransaction');
			await startTransferTransaction();
			return true;
		} catch (err) {
			handleErrorMessage(err);
			console.log(err);
			return false;
		} finally {
			setIsLoading(false);
		}
	};

	const checkRequiredBalance = async () => {
		try {
			console.log('checkRequiredBalance');
			updateSteps('requiredAmount', true, false, false);
			const balance = await readContract({
				chainId,
				contractAddress: tokenReward?.tokenReward?.tokenAddress,
				ABI: erc20Abi,
				fnName: 'balanceOf',
				args: [address],
			});
			console.log('balance', balance);

			const tokenSymbol = tokenReward?.tokenReward?.tokenSymbol || 'Token';
			const formattedBalance = ethers.utils.formatUnits(
				balance as BigNumber,
				tokenReward?.tokenReward?.tokenDecimals,
			);
			setUserBalance(
				(
					+balance?.toString() /
					10 ** tokenReward?.tokenReward?.tokenDecimals
				)?.toString(),
			);
			if (!ethers.BigNumber.from(balance).gte(requiredAmount)) {
				const errorMessage = createErrorMessage.insufficientBalance(
					formattedBalance,
					ethers.utils.formatUnits(
						requiredAmount,
						tokenReward?.tokenReward?.tokenDecimals,
					),
					tokenSymbol,
				);
				throw new Error(errorMessage);
			}
			updateSteps('requiredAmount', false, true, false);
		} catch (err) {
			const errorMessage =
				err instanceof Error
					? err.message
					: `Failed to check ${tokenReward?.tokenReward?.tokenSymbol || 'token'} balance`;

			updateSteps('requiredAmount', false, false, true, {
				error: {
					message: errorMessage,
					code: 'INSUFFICIENT_BALANCE',
				},
			});
			console.log('error in fetching token balance');
			throw err;
		}
	};

	const startApprovalTransaction = async () => {
		try {
			setStatus('(1/2) Approve Token Transaction');
			updateSteps('approveToken', true, false, false);
			const receiver = contract.address;
			const args = [receiver, requiredAmount];

			try {
				const approvedAmount = await readContract({
					chainId,
					contractAddress: tokenReward?.tokenReward?.tokenAddress,
					ABI: erc20Abi,
					fnName: 'allowance',
					args: [address, receiver],
				});
				if (ethers.BigNumber.from(approvedAmount).gte(requiredAmount)) {
					//already has the required approval
					analytics.track(TrackingEvents.LaunchQuestTokenApproved, {
						preApproved: true,
						chainId,
					});

					const approvedAmount_ = (
						+approvedAmount?.toString() /
						10 ** tokenReward?.tokenReward?.tokenDecimals
					)?.toFixed(4);

					updateSteps('approveToken', false, true, false, {
						successMessage: `Token approval already granted for ${approvedAmount_}$${tokenReward?.tokenReward?.tokenSymbol}`,
					});
					await updateCampaignAdminAddress(campaignId, {
						adminAddress: address,
					});
					return;
				}
			} catch (err) {
				console.log(err);
				console.log('error in fetching token allowance');
			}
			const txn = await startTxn({
				chainId,
				contractAddress: tokenReward?.tokenReward?.tokenAddress,
				ABI: erc20Abi,
				fnName: 'approve',
				args: [receiver, requiredAmount?.toString()],
			});

			if (!txn || !txn.transactionHash) {
				console.log(
					'Token Approval Transaction failed, please reach out to support',
					txn,
				);
				throw new Error(
					'Token Approval Transaction failed, please reach out to support',
				);
			}
			updateSteps('approveToken', false, true, false, {
				txHash: txn?.transactionHash,
				scanLink: getScanLink(txn?.transactionHash, chainId, 'tx'),
			});
			analytics.track(TrackingEvents.LaunchQuestTokenApproved, {
				preApproved: false,
				chainId,
			});

			await updateTokenRewardApprovalTxn(campaignId, {
				adminAddress: txn.account,
				rewardId: tokenReward._id,
				txHash: txn.transactionHash,
				status: 'completed',
				chainId: chainId,
				receipt: txn.receipt,
				chain: tokenReward?.tokenReward?.chain,
				namespaceTag: tokenReward?.tokenReward?.namespaceTag,
			});
			return;
		} catch (err) {
			const tokenSymbol = tokenReward?.tokenReward?.tokenSymbol || 'Token';
			const errorMessage =
				err instanceof Error
					? err.message
					: createErrorMessage.tokenApprovalFailed(tokenSymbol);

			updateSteps('approveToken', false, false, true, {
				error: {
					message: errorMessage,
					code: 'TOKEN_APPROVAL_FAILED',
				},
			});
			throw err;
		}
	};

	const startTransferTransaction = async () => {
		const tokenSymbol = tokenReward?.tokenReward?.tokenSymbol || 'Token';
		const rewardPool = (
			tokenReward?.tiersRewardPool
				? +tokenReward?.tiersRewardPool
				: +tokenReward?.tokenReward?.tokenAmountPerUser *
					tokenReward?.numRewards
		)?.toString();
		try {
			console.log('startTransferTransaction');

			updateSteps('transferToken', true, false, false);
			const res = await getTokenRewardTransferSignature(campaignId);
			const signatureData = res.txnData;
			setStatus('(2/2) Transfer Token Transaction');

			const agrs = formatArgs(signatureData.functionParams);

			console.log('quest-amount', agrs);

			const txn = await startTxn({
				chainId,
				contractAddress: contract.address,
				ABI: ERC20RewardABI,
				fnName: signatureData.functionName,
				args: agrs,
			});
			if (!txn || !txn?.transactionHash) {
				throw new Error(
					createErrorMessage.transferFailed(tokenSymbol, rewardPool),
				);
			}
			console.log('txn', txn);
			const receipt = txn?.receipt;
			if (!receipt?.decodedLogs) {
				throw new Error(
					`Verification failed for ${rewardPool?.toString()} ${tokenSymbol} transfer`,
				);
			}
			await updateTokenRewardTransferTxn(campaignId, {
				txHash: txn.transactionHash,
				status: 'completed',
				chainId: chainId,
				receipt: txn.receipt,
				chain: tokenReward?.tokenReward?.chain,
				namespaceTag: tokenReward?.tokenReward?.namespaceTag,
			});
			analytics.track(TrackingEvents.LaunchQuestTokenTransferred, {
				chainId,
			});
			updateSteps('transferToken', false, true, false, {
				txHash: txn?.transactionHash,
				scanLink: getScanLink(txn?.transactionHash, chainId, 'tx'),
			});
			setStatus('Transaction Completed');
		} catch (err) {
			const tokenSymbol = tokenReward?.tokenReward?.tokenSymbol || 'Token';

			const errorMessage =
				err instanceof Error
					? err.message
					: createErrorMessage.transferFailed(tokenSymbol, rewardPool);

			updateSteps('transferToken', false, false, true, {
				error: {
					message: errorMessage,
					code: 'TOKEN_TRANSFER_FAILED',
				},
			});
			throw err;
		}
	};

	const startSpeedRunTransaction = async () => {
		try {
			if (!speedRunConfig?.isEnrolled) return;
			if (speedRunConfig?.transaction?.txHash) {
				updateSteps('transferSpeedRun', false, true, false, {
					txHash: speedRunConfig?.transaction?.txHash,
					scanLink: getScanLink(
						speedRunConfig?.transaction?.txHash,
						chainId,
						'tx',
					),
				});
				return;
			}
			if (isSpeedrunDone) {
				updateSteps('transferSpeedRun', false, true, false);
			}
			updateSteps('transferSpeedRun', true, false, false);

			const tokenAmount = speedRunConfig?.tokenAmount;
			const tokenSymbol = tokenReward?.tokenReward?.tokenSymbol || 'Token';
			const formattedAmount = ethers.utils.parseUnits(
				tokenAmount,
				tokenReward?.tokenReward?.tokenDecimals,
			);
			console.log('speed-run-amount', formattedAmount);

			const speedrunAddress = SPEEDRUN_USER_ADDRESS;
			const txn = await startTxn({
				chainId,
				contractAddress: tokenReward?.tokenReward?.tokenAddress,
				ABI: erc20Abi,
				fnName: 'transfer',
				args: [speedrunAddress, formattedAmount],
			});
			console.log('tx', txn);

			if (!txn || !txn?.transactionHash) {
				throw new Error(
					createErrorMessage.speedrunTransferFailed(
						tokenSymbol,
						tokenAmount,
					),
				);
			}
			await updateSpeedrunTransferTxn(campaignId, {
				txHash: txn.transactionHash,
				status: 'completed',
				chainId: chainId,
				receipt: txn.receipt,
				chain: tokenReward?.tokenReward?.chain,
				namespaceTag: tokenReward?.tokenReward?.namespaceTag,
			});
			analytics.track(TrackingEvents.LaunchQuestTokenTransferred, {
				chainId,
			});
			updateSteps('transferSpeedRun', false, true, false, {
				txHash: txn?.transactionHash,
				scanLink: getScanLink(txn?.transactionHash, chainId, 'tx'),
			});
			setStatus('Speedrun Transaction Completed');

			await queryClient.invalidateQueries({
				queryKey: ['campaign', campaignId],
			});
			setIsSpeedrunDone(true);
		} catch (err) {
			const tokenSymbol = tokenReward?.tokenReward?.tokenSymbol || 'Token';

			const errorMessage =
				err instanceof Error
					? err.message
					: createErrorMessage.speedrunTransferFailed(
							tokenSymbol,
							speedRunConfig?.tokenAmount,
						);

			updateSteps('transferSpeedRun', false, false, true, {
				error: {
					message: errorMessage,
					code: 'SPEEDRUN_TRANSFER_FAILED',
				},
			});
			throw err;
		}
	};

	return {
		startProcess,
		startApprovalTransaction,
		startTransferTransaction,
		isLoading,
		isConnected: !!address,
		isChainSelected: chainId === selectedChainId,
		status,
		steps,
		userBalance,
		requiredAmount,
	};
};

function formatArgs(args: any) {
	return args.map((arg: any) => {
		if (Array.isArray(arg)) {
			return arg.map((item) => ({
				rewardId: ethers.BigNumber.from(item.rewardId.hex),
				tokenAddress: item.tokenAddress,
				numRewards: ethers.BigNumber.from(item?.numRewards.hex),
			}));
		} else if (arg.type && arg.type === 'BigNumber') {
			return ethers.BigNumber.from(arg.hex);
		} else {
			return arg;
		}
	});
}
